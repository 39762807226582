import { mapGetters } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import config from 'config'

export default {
  components: {
    Breadcrumbs
  },
  data () {
    return {
      loading: true
    }
  },
  serverPrefetch () {
    return this.fetchPosts()
  },
  mounted () {
    if (!this.posts?.length) {
      this.fetchPosts()
    }
  },
  computed: {
    ...mapGetters({
      posts: 'wordpress/press'
    }),
    firstColumn () {
      return this.posts.filter(post => post.page_layout === '1column')
    },
    secondColumn () {
      return this.posts.filter(post => post.page_layout !== '1column')
    }
  },
  filters: {
    prettyDate: function (date) {
      let newDate = new Date(date.replace(/-/g, '/'))
      let dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return newDate.toLocaleDateString('pl-PL', dateFormat)
    }
  },
  methods: {
    async fetchPosts () {
      await this.$store.dispatch('wordpress/loadPress')
    },
    sanitizeHtml: sanitizeHtml
  },
  metaInfo () {
    return {
      title: 'Press',
      meta: [
        { vmid: 'description', name: 'description', description: 'Blog modowy dla eleganckich kobiet. Aktualne trendy, inspiracje, sesje zdjęciowe, wiadomości z targów, opinie projektantów. Do poczytania na E-Garderobe.pl!' },
        { vmid: 'og:url', property: 'og:url', content: config.server.hostName + '/pl/press' },
        { vmid: 'og:description', property: 'og:description', content: 'Blog modowy dla eleganckich kobiet. Aktualne trendy, inspiracje, sesje zdjęciowe, wiadomości z targów, opinie projektantów. Do poczytania na E-Garderobe.pl!' },
        { vmid: 'og:title', property: 'og:title', content: 'Ress' }
      ]
    }
  }
}
