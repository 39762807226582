<template>
  <div class="blog-page blog-page-posts-index mb100">
    <div class="container pt10">
      <breadcrumbs
        :with-homepage="true"
        class="blog-bradcrumbs"
        :routes="[]"
        :active-route="'Prasa'"
      />
      <div class="blog-page--hero">
        <p class="blog-page--page-title">
          {{ $t('Talkin’ about us downtown!') }}
        </p>
      </div>
    </div>
    <div v-if="loading && !(posts && posts.length > 0)">
      <div class="blog-page--entries">
        <div class="container p0">
          <div class="row row--entries">
            <div class="col col-xs-12 col-md-6 col-lg-4">
              <div class="blog-page--preview-small">
                <p class="blog-page--preview-small-bg bg-cl-secondary" />
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date h-16 bg-cl-secondary w-50 brdr-radius-20" />
                  <h3 class="blog-page--preview-title h-25 mt-5 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt h-148 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt w-40 h-16 bg-cl-secondary mt-40 brdr-radius-20" />
                </div>
              </div>
            </div>
            <div class="col col-xs-12 col-md-6 col-lg-4">
              <div class="blog-page--preview-small">
                <p class="blog-page--preview-small-bg bg-cl-secondary" />
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date h-16 bg-cl-secondary w-50 brdr-radius-20" />
                  <h3 class="blog-page--preview-title h-25 mt-5 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt h-148 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt w-40 h-16 bg-cl-secondary mt-40 brdr-radius-20" />
                </div>
              </div>
            </div>
            <div class="col col-xs-12 col-md-6 col-lg-4">
              <div class="blog-page--preview-small">
                <p class="blog-page--preview-small-bg bg-cl-secondary" />
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date h-16 bg-cl-secondary w-50 brdr-radius-20" />
                  <h3 class="blog-page--preview-title h-25 mt-5 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt h-148 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt w-40 mt-40 h-16 bg-cl-secondary mt40 brdr-radius-20" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="(posts && posts.length > 0) || loading">
      <div class="container posts-conatainer p0">
        <div class="row mb20">
          <div class="col-xs-12 col-md-5">
            <div class="col-xs-12" v-for="(post) in firstColumn" :key="post.identifier">
              <div class="blog-page--preview-small">
                <div class="press-page--preview-small-inner">
                  <div class="press-page--data-box">
                    <h2 class="blog-page--preview-title" v-html="post.title" />
                    <h3 class="press-page--preview-excerpt no-break weight-400" v-html="post.filtered_content" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-7 brdr-left-1 brdr-cl-secondary">
            <div class="col-xs-12" v-for="(post) in secondColumn" :key="post.identifier">
              <div class="blog-page--preview-small">
                <div class="press-page--preview-small-inner">
                  <div class="press-page--image-box">
                    <img v-lazy="post.featured_image" alt="post image" width="100%" height="100%">
                  </div>
                  <div class="blog-page--data-box">
                    <router-link :to="localizedRoute(`/prasa/post/${post.identifier}`)" class="blog-page--preview-small">
                      <h2 class="blog-page--preview-title" v-html="post.title" />
                    </router-link>
                    <h3 class="press-page--preview-excerpt no-break weight-400" v-html="post.filtered_content" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>{{ $t('There are no posts.') }}</h3>
    </div>
  </div>
</template>

<script>
import Posts from 'src/modules/vsf-wordpress/components/press/Index.js'
import Loader from 'theme/components/core/LoadingSpinner.vue'

export default {
  components: {
    Loader
  },
  mixins: [Posts],
  name: 'PostsIndex',
  methods: {
    textFilter (text, tagName) {
      if (text === 'Continued' && tagName === 'a') {
        return text.replace('Continued', '')
      } else {
        return text
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/vsf-wordpress/components/blog/scss/blog';

.press-page--preview-small-inner {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 570px) {
    flex-direction: column;
  }
}
.blog-page--data-box {
  @media only screen and (max-width: 570px) {
    padding-left: 0;
    max-width: 100%;
    flex-basis: 100%;
    text-align: left;
  }
  flex-basis: 70%;
  max-width: 70%;
  padding-left: 30px;
  position: relative;
}
.press-page--data-box {
  @media only screen and (max-width: 570px) {
    padding-left: 0;
    max-width: 100%;
    flex-basis: 100%;
    text-align: left;
  }
  flex-basis: 100%;
  max-width: 100%;
  position: relative;
}
.press-page--image-box {
  @media only screen and (max-width: 570px) {
    padding-top: calc(100% * 1/0.9);
  }
  flex-basis: 30%;
  position: relative;
  display: flex;
  overflow: hidden;
  height: 0;
  padding-top: calc(100% * 1/2);
  img {
    position: absolute;
    top: 0;
    object-fit: cover;
    transition: 0.3s all;
    &:hover {
      transform: scale(1.2);
    }
  }
}
.loader-text {
  font-size: 18px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.blog-page-posts-index {
  .blog-page--hero {
    padding-top: 10px;
    text-align: center;
    @media (max-width: 987px) {
      padding-bottom: 0px;
    }
    @media (max-width: 767px) {
      padding-top: 0px;
    }
  }
  .blog-page--page-title {
    @media (max-width: 767px) {
      margin-top: 0px;
      font-size: 24px;
      margin-bottom: 0px;
    }
    display: inline;
    text-align: center;
    color: $nearly-black;
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 35px;
    font-family: 'Angelica Pro Display';
  }
}
.category-filters {
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  border: 1px solid #CCCCCC;
  padding: 10px 20px;
  border-radius: 10px;
  .filter-label {
    color: black;
    &:hover {
      &::after {
        content: "";
        position: absolute;
        left: 8px;
        top: 5px;
        border-bottom: 2px solid #FFF;
        border-right: 2px solid #FFF;
        transform: rotate(45deg);
        width: 5px;
        height: 10px;
      }
    }
    &::before {
      content: "";
      position: relative;
      top: 1px;
      border-radius: 4px;
      margin-right: 20px;
      min-width: 20px;
      min-height: 20px;
      border: 1px solid #CCCCCC;
    }
    &.active {
      &::after {
        content: '';
        position: absolute;
        left: 8px;
        top: 5px;
        width: 5px;
        height: 10px;
        border-bottom: 2px solid #FFF;
        border-right: 2px solid #FFF;
        transform: rotate(45deg);
      }
    }
  }
}
.show-more {
  color: #008329;
  font-weight: 700;
  font-size: 18px;
}
.blog-page--preview-large-inner {
  @media (max-width: 767px) {
    border-radius: 7px !important;
    padding: 15px !important;
    .blog-page--preview-date {
      text-align: right;
    }
    .blog-page--preview-title {
      font-size: 18px !important;
      line-height: 26px !important;
    }
  }
}
  .no-break {
    word-break: break-word;
  }
  .h-44 {
    height: 44px;
  }
  .h-25 {
    height: 25px;
  }
  .h-16 {
    height: 16px;
  }
  .h-148 {
    height: 148px;
  }
  .w-40 {
    width: 40%;
  }
  .mt-40 {
    margin-top: 40px !important;
  }
  .mt-5 {
    margin-top: 5px !important;
  }
  .category-link {
    white-space: nowrap;
  }
  .category-link:hover {
    text-decoration: underline;
  }
  .blog-bradcrumbs {
    justify-content: center !important;
  }
  .blog-page--preview-title {
    font-size: 24px !important;
    text-transform: initial !important;
    @media (max-width: 767px) {
      margin-top: 20px !important;
    }
  }
</style>
